import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function Welcome({ name }) {
  return (
    <Wrapper>
      <Greetings>Hi, {name}!</Greetings>

      <Intro>
        <strong>Forget someone's name? Need a number?</strong> This site is a
        contact listing for residents of Date Palm Manor. Please don't share
        your login info. Thanks!
      </Intro>
      <Intro>
        {' '}
        Missing some info? Have a new family member (human or not)? Please let
        me know: <a href="mailto:billpliske@gmail.com">billpliske@gmail.com</a>
      </Intro>
      <StyledLink to="/map">Go to map</StyledLink>
      <RegularLink href="mailto:divingbells@gmail.com,annamarie@asu.edu,Partsy1@yahoo.com,roberttomasino@ymail.com,dalespier@msn.com,daphnegracey@gmail.com,dpreece@cox.net,acegracey@aol.com,Robertsteamaz@gmail.com,deborahhsussman@gmail.com,devistrong39@gmail.com,egomezd@hotmail.com,Elizabethcranern@gmail.com,gnelson49@cox.net,gaspartonnesen@gmail.com,hjenkins3@cox.net,owlboy@cox.net,azjaneen@yahoo.com,john.engen@cox.net,whonelson@cox.net,robertsteamaz@gmail.com,lawrencemrose@gmail.com,spahrml@gmail.com,manjula@mac.com,mvaz@gblaw.com,mollyv31@gmail.com,mortm@cox.net,neil.borowicz@gmail.com,nicki.gammage@gmail.com,pat.lauderdale@asu.edu,sclrose@gmail.com,aocqua@aol.com, walkerpliske@gmail.com,billpliske@gmail.com,Lucky3339@gmail.com,jeff012565shepard@msn.com">
        Email everyone in Date Palm
      </RegularLink>
    </Wrapper>
  );
}

Welcome.propTypes = {
  name: PropTypes.string,
};

const Wrapper = styled.div`
  margin: 15px auto 45px auto;
  max-width: 500px;
  position: relative;
`;

const Greetings = styled.h1`
  text-transform: capitalize;
  padding: 25px 25px 25px 20px;
  color: white;
  font-size: 30px;
  line-height: 35px;
`;

const Intro = styled.p`
  font-weight: 500;
  padding: 0 20px 20px 20px;
  a {
    color: white;
  }
`;

const StyledLink = styled(Link)`
  background-color: white;
  border-radius: 30px;
  color: black;
  font-weight: 600;
  margin: 0px 0 0 20px;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
`;

const RegularLink = styled.a`
  background-color: #ceb7c6;
  border-radius: 30px;
  color: black;
  font-weight: 600;
  margin: 10px 0 0 20px;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
`;
